<template>
  <div class="d-block" v-if="modalShow">
    <b-modal
      v-model="modalShow"
      class="registerModal"
      ref="registerModal"
      hide-header
      hide-footer
      centered
      body-class="p-0"
    >
      <div>
        <div class="m-auto text-center p-3 bg-white"></div>
      </div>
      <div class="text-center p-3">
        <div class="font-weight-bold f-size-18">
          Welcome, {{ lineProfile.displayName }}
        </div>
        <div>ขอต้อนรับ {{ $BRAND }} Member</div>
      </div>
      <div class="border-top p-2 text-center btn-confirm-popup">
        <div @click="close">ปิดหน้าต่างนี้</div>
      </div>
    </b-modal>
  </div>
</template>

<script>
// import { mapGetters } from "vuex";
import VueCookies from "vue-cookies";
export default {
  props: {
    lineProfile: {
      required: false,
      type: Object
    }
  },
  data() {
    return {
      modalShow: false
    };
  },
  // computed: {
  //   ...mapGetters({
  //     userProfile: "getUserProfile",
  //     isLoggedin: "getIsLoggedin"
  //   })
  // },
  methods: {
    show() {
      this.modalShow = true;
    },
    close() {
      this.modalShow = false;
      VueCookies.remove("popupModalRegister");
    }
  }
};
</script>

<style lang="scss" scoped>
.modal-body {
  margin: auto;
  text-align: center;
}
.image-logo {
  width: 100%;
  height: 100%;
}
</style>
