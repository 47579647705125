var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content-profile"},[_c('div',{staticClass:"profile-bg"},[_c('div',{staticClass:"edit-profile-icon",on:{"click":function($event){return _vm.$router.push('editprofile')}}},[_c('b-dropdown',{attrs:{"no-caret":"","right":"","variant":"custom-link","toggle-class":"text-decoration-none"},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('font-awesome-icon',{staticClass:"icon-edit-profile",attrs:{"icon":['fas', 'ellipsis-v']}})]},proxy:true}])},[_c('b-dropdown-item',{on:{"click":function($event){return _vm.$router.push('/editprofile')}}},[_c('div',{staticClass:"text-center"},[_c('span',[_vm._v("Edit Profile")])])])],1)],1),_c('div',{staticClass:"profile-image"},[(_vm.userProfile.pictureUrl)?_c('div',{staticClass:"profile-img",style:({
          backgroundImage: 'url(' + _vm.userProfile.pictureUrl + ')'
        }),on:{"click":_vm.modalQR}}):(_vm.profile.picture)?_c('div',{staticClass:"profile-img",style:({
          backgroundImage: 'url(' + _vm.profile.picture + ')'
        }),on:{"click":_vm.modalQR}}):_c('div',{staticClass:"profile-img",style:({
          backgroundImage: 'url(' + _vm.pictureProfile + ')'
        }),on:{"click":_vm.modalQR}})])]),_c('div',{staticClass:"profile-detail"},[_c('div',{staticClass:"member-badge",style:(`--bg-color: ${_vm.profile.bg_color};--font-color: ${_vm.profile.font_color};`)},[_c('b-img',{staticClass:"member-icon",attrs:{"src":_vm.profile.icon}}),_vm._v(" "+_vm._s(_vm.profile.display)+" ")],1),_c('div',{staticClass:"text-center"},[_c('div',{staticClass:"text-bold f-20"},[_vm._v(" "+_vm._s(_vm.profile.first_name_th)+" "+_vm._s(_vm.profile.last_name_th)+" ")]),(_vm.profile.email)?_c('div',{staticClass:"f-13"},[_vm._v(_vm._s(_vm.profile.email))]):_vm._e()]),_c('div',{staticClass:"profile-panel-detail"},[_c('div',[_c('div',{staticClass:"icon-profile"},[_c('font-awesome-icon',{attrs:{"icon":['fas', 'birthday-cake']}})],1),_c('span',[_vm._v(_vm._s(_vm.profile.have_year_birth === false ? _vm.dateFormat(_vm.profile.birthday, "DD/MM") : _vm.dateFormat(_vm.profile.birthday, "DD/MM/YYYY")))])]),_c('div',[_c('div',{staticClass:"icon-profile"},[_c('font-awesome-icon',{attrs:{"icon":['fas', 'phone-alt']}})],1),_c('span',[_vm._v(_vm._s(_vm.convertTelePhone(_vm.profile.telephone) || "-"))])])]),_c('div',{staticClass:"logout-btn"},[_c('hr'),_c('div',{on:{"click":function($event){return _vm.$emit('logout')}}},[_c('font-awesome-icon',{staticClass:"text-black",attrs:{"icon":['fas', 'sign-out-alt']}}),_vm._v(" Log out ")],1)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }