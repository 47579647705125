<template>
  <div>
    <b-modal
      v-model="showModal"
      ref="ModalQRUser"
      dialog-class="p-4"
      hide-header
      centered
      hide-footer
      @hidden="hide"
    >
      <div>
        <div class="text-center my-3 title-profile">MY ALO ACCESS QR</div>
        <div class="border-behind"></div>

        <table class="mx-auto mt-3">
          <tr>
            <td>Member ID</td>
            <td>: {{ profile.member_id }}</td>
          </tr>
        </table>

        <div class="text-center">
          <vue-qrcode
            class="qr-width-70"
            :value="profile.member_id"
            v-if="profile.member_id"
          />
        </div>
        <div class="timer-countdown" v-if="displayCountdown">
          <div>This QR code will be expire within 10 minutes.</div>
          <div class="text-danger text-bold">
            {{ countTime }}
          </div>
        </div>
      </div>
      <div class="close-modal" @click="hide">
        <font-awesome-icon :icon="['fas', 'times']" class="icon" />
      </div>
    </b-modal>
  </div>
</template>

<script>
import VueQrcode from "vue-qrcode";
import picture from "@/assets/images/default-image.png";
const moment = require("moment");
var x;
export default {
  props: {
    profile: {
      required: true,
      type: Object
    },
    userProfile: {
      required: false,
      type: Object
    }
  },
  components: {
    VueQrcode
  },
  mounted() {},
  data() {
    return {
      showModal: false,
      pictureProfile: picture,
      countTime: "00:00",
      displayCountdown:
        this.$store.state.theme.line_display_code_countdown == "1"
          ? true
          : false
    };
  },
  methods: {
    show() {
      this.showModal = true;
      this.countTimer();
    },
    hide() {
      this.showModal = false;
      clearInterval(x);
    },

    countTimer() {
      function padZero(value) {
        value = String(value);
        return value.length == 1 ? "0" + value : value;
      }
      var countDownDate = moment().add(10, "minutes").valueOf();
      x = setInterval(() => {
        var now = moment().valueOf();
        var distance = countDownDate - now;
        var days = Math.floor(distance / (1000 * 60 * 60 * 24));
        var hours = padZero(
          Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
        );
        var minutes = padZero(
          Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60))
        );
        var seconds = padZero(Math.floor((distance % (1000 * 60)) / 1000));

        if (distance < 0) {
          this.hide();
          return clearInterval(x);
          // this.countTime = "EXPIRED";
        }
        this.$nextTick(() => {
          this.countTime = minutes + " : " + seconds;
        });
      }, 900);
    }
  }
};
</script>

<style lang="scss" scoped>
.text-bold {
  font-weight: 600;
  font-size: 18px;
  padding: 10px 0;
}
.profile-img {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border: none;
  border-radius: 50%;
}
.panel-image {
  width: 100px;
  height: 100px;
  cursor: pointer;
  border: 3px solid #000;
  border-radius: 50%;
  bottom: 0px;
}
.title-profile {
  font-weight: bold;
  font-size: 20px;
}
.border-behind {
  border-top: 1px dashed #dfdfdf;

  width: 100%;
}
.img-zindex {
  z-index: 1;
  position: relative;
}
.dot {
  display: inline-block;
  top: 30%;
  position: absolute;
  width: 20px;
  height: 35px;
  border-left: 0;
  background-color: gray;
}
.dot-left {
  margin-left: -17px;
  border-bottom-right-radius: 100px;
  border-top-right-radius: 100px;
}
.dot-right {
  border-bottom-left-radius: 100px;
  border-top-left-radius: 100px;
  margin-right: -16px;
}
.color-gray-ft {
  color: #9d9d9d;
}
.qr-width-70 {
  width: 80%;
}
.timer-countdown {
  text-align: center;
  font-size: 14px;
  margin: 1rem 0;
  color: black;
}
.close-modal {
  position: absolute;
  bottom: -10%;
  left: 50%;
  transform: translate(-50%, 10%);
  width: 32px;
  height: 32px;
  color: white;
  border-radius: 50%;
  border: 1px solid white;
  display: inline-flex;
  cursor: pointer;
}
.icon {
  width: 16px;
  height: 16px;
  margin: auto;
}
</style>
