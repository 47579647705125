<template>
  <div>
    <div class="member-detail-panel p-2 p-sm-4" @click="modalQR()">
      <vue-qrcode class="qr" :value="profile.member_id" />

      <div class="member-detail">
        <div class="f-16 text-bold">ALO ACCESS</div>
        <div class="f-13 text-bold">
          {{ profile.first_name_th }} {{ profile.last_name_th }} <br />Member
          ID:
          {{ profile.member_id }}
        </div>
        <div class="f-11">
          Member since:
          {{ $moment(profile.created_time).format("DD MMM YYYY") }}
        </div>
      </div>
    </div>

    <ModalQRUser
      ref="ModalQRUser"
      :profile="profile"
      :userProfile="userProfile"
      class="mx-2"
    />
  </div>
</template>

<script>
import ModalQRUser from "@/components/profile/modal/ModalQRUser";
import VueQrcode from "vue-qrcode";
import { methods } from "@chenfengyuan/vue-countdown";
export default {
  props: {
    profile: {
      required: true
    },
    userProfile: {
      required: true
    }
  },
  components: {
    VueQrcode,
    ModalQRUser
  },
  methods: {
    modalQR() {
      this.$refs.ModalQRUser.show();
    }
  }
};
</script>

<style>
.member-detail-panel {
  margin: 1rem;
  background: linear-gradient(
    45deg,
    rgba(0, 0, 0, 1) 0%,
    rgba(94, 94, 94, 1) 37%,
    rgba(110, 109, 109, 1) 45%,
    rgba(0, 0, 0, 1) 97%
  );
  /* padding: 2rem 1.25rem; */
  border-radius: 0.75rem;
  display: flex;
}
.qr {
  border-radius: 0.5rem;
}
.member-detail {
  display: flex;

  justify-content: space-between;
  font-size: 14px;
  flex-direction: column;
  padding: 0 0.75rem;
  color: white;
}
</style>
