<template>
    <div>
        <PrivilegeCardMenu 
            v-if="privilegeList.length > 0"
            :list="privilegeList"
            :title="'PRIVILEGE'"
        />
        <RewardListMenu
            v-if="rewardList.length > 0"
            :list="rewardList"
            :title="'REWARD'"
        />
        <NewsCardMenu 
            v-if="newsList.length > 0"
            :list="newsList"
            :title="'NEWS'"
        />
    </div>
</template>

<script>
import PrivilegeCardMenu from "@/components/profile/PrivilegeCardMenu";
import NewsCardMenu from "@/components/profile/NewsCardMenu";
import RewardListMenu from "@/components/profile/RewardListMenu";
export default {
    components:{
        PrivilegeCardMenu,
        NewsCardMenu,
        RewardListMenu
    },
    props:{
        privilegeList:{
            required:true,
            type:Array
        },
        rewardList:{
            required:true,
            type:Array
        },
        newsList:{
            required:true,
            type:Array
        }
    }
}
</script>

<style lang="scss" scoped>
</style>