<template>
  
    <div class="bg-menulist">
      <div
        class="menu-list"
        v-for="list of MenuList"
        :key="list.id"
        @click="$router.push(list.path + `?title=${list.name}`)"
        :style="`--bg-color:${list.bg_color || 'white'}`"
      >
        <b-row class="align-items-center">
          <b-col cols="3" class="text-center">
            <span class="px-3">
              <template v-if="list.path == 'reward-transaction'">
                <div class="position-reletive d-inline">
                  <div class="random-reward" style="top: 10%">
                    <font-awesome-icon :icon="['fas', 'gift']" class="ft-16" />
                  </div>
                  <div class="random-reward" style="top: 2%">
                    <font-awesome-icon
                      :icon="['fas', 'expand']"
                      class="expand"
                    />
                  </div>
                </div>
              </template>
              <template v-else>
                <font-awesome-icon :icon="['fas', list.icon]" class="ft-20" />
              </template>
            </span>
          </b-col>
          <b-col class="p-0" cols="5">
            <span>{{ list.name }}</span>
          </b-col>
          <b-col cols="2" class="text-center">
            <b-badge
              variant="danger"
              pill
              class="px-2 mb-1"
              v-show="
                (profile.total_random_reward &&
                  list.path == 'reward-transaction') ||
                (profile.total_coupon_can_use && list.path == 'privilege')
              "
            >
              <span v-if="list.path == 'privilege'">{{
                profile.total_coupon_can_use
              }}</span>
              <span v-else>{{ profile.total_random_reward }}</span>
            </b-badge>
          </b-col>
          <b-col cols="2">
            <font-awesome-icon
              :icon="['fas', 'chevron-right']"
              class="icon-color"
            />
          </b-col>
        </b-row>
      </div>
    </div>
 
</template>

<script>
export default {
  props: ["MenuList", "profile"],
  methods: {}
};
</script>

<style lang="scss" scoped>
.bg-menulist {
  // background-color: #f1f1f1;
  .menu-list {
    &:first-of-type {
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
    }
    &:last-of-type {
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
    }
  }
}

.bg-menulist .menu-list:not(:last-child) {
  border-bottom: 1px solid #e1e1e1;
}
.menu-list {
  background-color: var(--bg-color);
  padding: 20px 0;
}
.menu-list-bottom {
  padding: 20px 0;
}
.ft-20 {
  font-size: var(--text-xl);
}
.icon-color {
  color: #c9c9c9;
}
.random-reward {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
.expand {
  font-size: 30px;
}
</style>
