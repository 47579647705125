<template>
  <div class="content-profile">
    <div class="profile-bg">
      <div class="edit-profile-icon" @click="$router.push('editprofile')">
        <b-dropdown
          no-caret
          right
          variant="custom-link"
          toggle-class="text-decoration-none"
        >
          <template #button-content>
            <font-awesome-icon
              :icon="['fas', 'ellipsis-v']"
              class="icon-edit-profile"
            />
          </template>
          <b-dropdown-item @click="$router.push('/editprofile')">
            <div class="text-center">
              <span>Edit Profile</span>
            </div>
          </b-dropdown-item>
        </b-dropdown>
        <!-- <font-awesome-icon icon="ellipsis-v"></font-awesome-icon> -->
      </div>
      <div class="profile-image">
        <div
          @click="modalQR"
          v-if="userProfile.pictureUrl"
          class="profile-img"
          v-bind:style="{
            backgroundImage: 'url(' + userProfile.pictureUrl + ')'
          }"
        ></div>
        <div
          @click="modalQR"
          v-else-if="profile.picture"
          class="profile-img"
          v-bind:style="{
            backgroundImage: 'url(' + profile.picture + ')'
          }"
        ></div>
        <div
          @click="modalQR"
          v-else
          class="profile-img"
          v-bind:style="{
            backgroundImage: 'url(' + pictureProfile + ')'
          }"
        ></div>
      </div>
    </div>

    <div class="profile-detail">
      <div
        class="member-badge"
        :style="`--bg-color: ${profile.bg_color};--font-color: ${profile.font_color};`"
      >
        <b-img :src="profile.icon" class="member-icon"></b-img>
        {{ profile.display }}
      </div>
      <div class="text-center">
        <div class="text-bold f-20">
          {{ profile.first_name_th }} {{ profile.last_name_th }}
        </div>
        <div v-if="profile.email" class="f-13">{{ profile.email }}</div>
      </div>

      <div class="profile-panel-detail">
        <div>
          <div class="icon-profile">
            <font-awesome-icon :icon="['fas', 'birthday-cake']" />
          </div>
          <span>{{
            profile.have_year_birth === false
              ? dateFormat(profile.birthday, "DD/MM")
              : dateFormat(profile.birthday, "DD/MM/YYYY")
          }}</span>
        </div>
        <div>
          <div class="icon-profile">
            <font-awesome-icon :icon="['fas', 'phone-alt']" />
          </div>
          <span>{{ convertTelePhone(profile.telephone) || "-" }}</span>
        </div>
      </div>

      <div class="logout-btn">
        <hr />
        <div @click="$emit('logout')">
          <font-awesome-icon
            :icon="['fas', 'sign-out-alt']"
            class="text-black"
          />
          Log out
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import VueQrcode from "vue-qrcode";
import picture from "@/assets/images/default-image.png";
import ModalQRUser from "@/components/profile/modal/ModalQRUser";
export default {
  components: {
    ModalQRUser,
    VueQrcode
  },
  props: {
    profile: {
      required: true,
      type: Object
    },
    userProfile: {
      required: false,
      type: Object
    },
    marketPlaceObj: {
      required: true
    },
    memberLevel: {
      required: false
    }
  },
  data() {
    return {
      pictureProfile: picture,
      displayLogoutButton:
        this.$store.state.theme.line_display_logout_button == "1"
          ? true
          : false,
      displayPointAndChannel:
        this.$store.state.theme.line_display_point_channel == "1"
          ? true
          : false,
      displayConnectChannel:
        this.$store.state.theme.display_connect_channel == "1" ? true : false,
      layoutDisplay: this.$store.state.theme.line_layout,
      displayMemberLevel:
        this.$store.state.theme.line_display_member_level == "1" ? true : false
    };
  },
  updated() {},
  created() {
    // this.getCompleteProfile();
  },
  methods: {
    dateFormat(value, format) {
      if (!value) return "-";
      if (!format) format = "DD-MM-YYYY";
      if (moment(value).format("YYYY") == "1753") return "-";
      return moment(value).format(format);
    },

    modalQR() {
      this.$refs.ModalQRUser.show();
    },
    handleMarketPlace() {
      if (this.marketPlaceObj.marketplace.length == 1) {
        return;
      }
      this.$router.push("/marketplace");
    },
    checkIsBranchPoint() {
      if (this.$store.state.theme.is_center_point == "0")
        return this.$router.push("/branchpoint");
      return this.$router.push("/point");
    },
    convertTelePhone(val) {
      if (!val) return null;

      return `${val.substring(0, 3)}-${val.substring(3, 6)}-${val.substring(
        6,
        10
      )}`;
    }
  }
};
</script>

<style lang="scss" scoped>
.icon-marketplace {
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;

    border-radius: 50%;
  }
  .cover {
    background: #858585;
    opacity: 0.5;
    position: absolute;
    top: 0;
    height: 30px;
    width: 30px;
    left: 0;
    border-radius: 50%;
  }
  border-radius: 50%;
  width: 30px;
  height: 30px;
  /* overflow: hidden; */
  position: relative;
  padding: 1px;
  margin-top: 2px;
}
.content-profile {
  margin: 1rem;

  background: white;
  // background-color: white;
  border-radius: 0.5rem;
}

.profile-img {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border: 1px solid white;
  border-radius: 50%;
}
.op-low {
  opacity: 0.15;
}
.profile-bg {
  width: 100%;
  height: auto;
  aspect-ratio: 16/7;
  background: rgb(187, 189, 167);
  background: linear-gradient(
    13deg,
    rgba(187, 189, 167, 1) 0%,
    rgba(230, 221, 207, 1) 33%,
    rgba(221, 212, 204, 1) 51%,
    rgba(186, 183, 189, 1) 69%,
    rgba(113, 131, 176, 1) 88%
  );
  position: relative;
  border-radius: 0.5rem 0.5rem 0 0;
}
.profile-image {
  width: 30%;
  height: auto;
  aspect-ratio: 1;
  position: absolute;
  bottom: -50%;
  left: 50%;
  transform: translate(-50%, -25%);
}
.profile-detail {
  padding: 1.75rem 1.25rem;
  width: 100%;
  height: auto;
  aspect-ratio: 16/9;
  border-radius: 0 0 0.5rem 0.5rem;
  margin-top: 10%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  row-gap: 1.25rem;
  margin-bottom: 85px;
}
.member-badge {
  background: var(--bg-color);
  color: var(--font-color);
  text-align: center;
  border-radius: 1.25rem;
  font-size: 12px;
  width: max-content;
  padding: 0.25rem 0.5rem;
}
.logout-btn {
  width: 100%;
  font-size: 14px;
  /* transform: translate(-10%, 14%); */
  color: black;
  line-height: 1.25rem;
  text-align: center;
}
.profile-panel-detail {
  background: rgba(243, 243, 243, 1);
  width: 100%;
  height: auto;
  padding: 1rem;
  border-radius: 0.25rem;
  display: flex;
  > div:first-child {
    border-right: 1px solid rgb(158 158 158 / 46%);
  }
  div {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 0.5rem;
    font-size: 14px;
  }
}
.icon-profile {
  border-radius: 50%;
  background: black;
  width: 30px;
  padding: 0.5rem 0.5rem;
  height: auto;
  color: white;
}
.member-icon {
  width: auto;
  height: 10px;

  margin-bottom: 2px;
}
@media screen and (max-width: 400px) {
}
.edit-profile-icon {
  position: absolute;
  top: 5px;
  right: 15px;
  font-size: 18px;
}
</style>
