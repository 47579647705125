<template>
  <div>
    <b-carousel
      v-model="slick"
      id="carousel-fade"
      :style="[bannerList.length == 1 ? 'margin-bottom:0.5rem' : '']"
      fade
      :indicators="bannerList.length > 1 ? true : false"
      :interval="interval"
      no-hover-pause
    >
      <b-carousel-slide
        v-for="item in bannerList"
        :key="item.id"
        class="pointer"
      >
        <template #img>
          <video
            class="img-fluid w-100 d-block aspect-16-9"
            muted
            autoplay
            controls
            playsinline
            :ref="'video-' + item.id"
            v-if="item.is_video == 1"
          >
            <source :src="item.image_url" type="video/mp4" />
          </video>
          <img
            v-else
            slot="img"
            class="d-block img-fluid w-100"
            :src="item.image_url"
            alt="image slot"
            v-on:click="getForm(item)"
          />
        </template>
      </b-carousel-slide>
    </b-carousel>
  </div>
</template>

<script>
export default {
  props: {
    bannerList: {
      required: true,
      type: Array
    },
    profile: {
      required: true,
      type: Object
    }
  },
  data() {
    return {
      slick: 0,
      interval: 5000,
      playsinline: true
    };
  },
  watch: {
    slick(val, oldVal) {
      let refsVideo = this.bannerList[val];
      let refsOldVideo = this.bannerList[oldVal];

      if (refsVideo.is_video) {
        if (this.$refs["video-" + refsVideo.id][0].ended) {
          this.$refs["video-" + refsVideo.id][0].currentTime = 0;
        }
        this.$refs["video-" + refsVideo.id][0].play();
        this.interval = this.$refs["video-" + refsVideo.id][0].duration * 1000;
      } else {
        this.interval = 5000;
      }
    }
  },
  mounted() {
    if (this.bannerList[0].is_video) {
      var video = document.createElement("video");
      video.preload = "metadata";
      video.onloadedmetadata = () => {
        window.URL.revokeObjectURL(this.bannerList[0].image_url);
        var duration = video.duration;
        this.interval = duration * 1000;
      };
      video.src = this.bannerList[0].image_url;
    }
  },
  methods: {
    getForm(item, index) {
      switch (item.mobile_redirect_type_id) {
        case 5:
          if (item.redirect_id.includes("http")) {
            window.location.href = item.redirect_id;
          } else {
            window.location.href = `https://${item.redirect_id}`;
          }
          break;
        case 6:
          const url = new URL(item.redirect_url);
          const parameter = url.searchParams.get("urlkey");
          this.$router.push(
            `/form?urlKey=${parameter}&path=${item.redirect_url}&guid=${this.profile.user_guid}`
          );
          break;
        case 7:
          this.$router.push(`/reward?title=${item.path_name || "ของรางวัล"}`);
          break;

        default:
          break;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.header-image {
  width: 100%;
  padding-top: 50%;
  background-size: cover;
  background-repeat: no-repeat;
}
::v-deep .carousel-indicators {
  position: relative;
  margin: 0;
  li {
    background-color: var(--primary-color);
    width: 5px;
    height: 5px;
    border-radius: 50%;
  }
}
::v-deep .carousel {
  img {
    border-radius: 5px;
  }
}
// .aspect-2-1 {
//   aspect-ratio: 2 / 1;
// }
.aspect-16-9 {
  aspect-ratio: 2 / 1;
}
</style>
