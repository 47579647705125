<template>
  <div class="content-page">
    <div v-if="isLoading">
      <OtherLoading variant="dark" />
    </div>
    <div v-else>
      <div class="header-title">
        <div>MEMBER INFO</div>
      </div>
      <MemberPanel :profile="profile" :userProfile="lineProfile" />

      <ProfileComponent
        :profile="profile"
        :userProfile="lineProfile"
        @logout="logout"
        :marketPlaceObj="marketPlaceObject"
      />

      <!-- <div class="menu-panel">
        <div>
          <div class="icon-profile">
            <font-awesome-icon class="f-20" :icon="['fas', 'clock']" />
          </div>
          <span>History</span>
        </div>
        <div @click="$router.push('/editprofile')">
          <div class="icon-profile">
            <font-awesome-icon class="f-20" :icon="['fas', 'user']" />
          </div>
          <span>Profile</span>
        </div>

    
      </div> -->
    </div>
    <ModalRegisterAlert ref="ModalRegisterAlert" :lineProfile="lineProfile" />
    <ModalConfirm
      ref="ModalConfirm"
      text="Would you like to logout?"
      @handler="unpairfunc"
    />
  </div>
</template>

<script>
import ProfileComponent from "@/components/profile/ProfileComponent";
import MenuListComponent from "@/components/profile/MenuListComponent";
import BannerProfilePanel from "@/components/profile/BannerProfilePanel";
import MemberPanel from "@/components/profile/MemberPanel";
import MenuListComponentOld from "@/components/profile/MenuListComponentOld";
import ModalRegisterAlert from "@/components/profile/modal/ModalRegisterAlert";
import ModalConfirm from "@/components/alert-modal/ModalConfirm";
import OtherLoading from "@/components/other/OtherLoading";
import VueCookies from "vue-cookies";
export default {
  components: {
    ProfileComponent,
    MenuListComponent,
    ModalRegisterAlert,
    ModalConfirm,
    OtherLoading,
    MenuListComponentOld,
    BannerProfilePanel,
    MemberPanel
  },
  mounted() {
    if (VueCookies.get("popupModalRegister")) {
      this.$refs.ModalRegisterAlert.show();
    }
  },
  data() {
    return {
      MenuList: [],
      profile: {},
      lineProfile: {},
      isLoading: true,
      filter: {
        SocialProvider: "line",
        SocialId: ""
      },
      privilegeList: [],
      notFoundData: false,
      rewardList: [],
      filterNews: {
        page: 1,
        take: 10
      },
      newsList: [],
      bannerList: [],
      filterBanner: {
        level_id: 1
      },
      marketPlaceObject: []
    };
  },

  async created() {
    this.liffLogin();
  },
  methods: {
    async liffLogin() {
      if (this.$liff.isLoggedIn()) {
        this.getProfileList();
      } else {
        this.$liff.login({
          redirectUri: this.$Endpoint + "profile"
        });
      }
    },
    async getUserProfile() {
      this.$cookies.set("hourglass_register_success", true);
      this.isLoading = true;
      await this.$store.dispatch("getUserApi");
      this.profile = this.$store.state.shortProfile;
      this.lineProfile = this.$cookies.get("user_profile_token");
      this.isLoading = false;
    },
    getProfileList() {
      this.$liff.getProfile().then(profile => {
        this.$cookies.set("user_profile_token", profile, 60 * 60 * 24 * 30);
        this.lineProfile = profile;
        this.LoginExternal();
      });
    },

    async unpairfunc() {
      this.filter.SocialId = this.lineProfile.userId;
      this.$axios
        .post(`${process.env.VUE_APP_API}/api/v1/user/unpair`, this.filter)
        .then(async data => {
          if (data.result == 1) {
            this.$cookies.remove("hourglass_token");
            // this.$cookies.remove("user_profile_token");
            this.$cookies.remove("hourglass_register_success");
            this.$router.push("/validatetelephone");
          }
        });
    },
    logout() {
      this.$refs.ModalConfirm.show();
    },
    async LoginExternal() {
      this.filter.SocialId = this.lineProfile.userId;
      this.$axios
        .post(
          `${process.env.VUE_APP_API}/api/v1/user/LoginExternal`,
          this.filter
        )
        .then(async data => {
          this.isLoading = false;
          if (data.result == 0) {
            this.$cookies.remove("hourglass_register_success");
            this.$cookies.remove("hourglass_token");
            if (this.$route.query.redirect) {
              return this.$router.replace(
                `/validatetelephone?redirect=${this.$route.query.redirect}`
              );
            }
            return this.$router.replace(`/validatetelephone`);
          } else {
            await this.$cookies.set("hourglass_token", data.detail.token);
            await this.getUserProfile();
            // await this.getBanner();
            // await this.getMenuList();
            // this.getMarketPlace();
          }
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.profile-container {
  background: white;
  margin: 1rem;
  border-radius: 5px;
  box-shadow: 0px 0px 5px -1px rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0px 0px 5px -1px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0px 0px 5px -1px rgba(0, 0, 0, 0.5);
}
.menulist-container {
  background: white;
  border-radius: 5px;
  margin: 1rem;
  box-shadow: 0px 0px 5px -1px rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0px 0px 5px -1px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0px 0px 5px -1px rgba(0, 0, 0, 0.5);

  // padding-bottom: 100px;
}
.text-profile {
  font-size: 23px;
  font-weight: 600;
}
.line-text-pf {
  border-left: 6px solid black;
}
.btn-unpair {
  background-color: transparent;
  border: none;
  color: #bebebe;
}
.w-90 {
  width: 90%;
}

.container-profile {
  padding-bottom: 96px;
}
.header-title {
  font-weight: 700;
  font-size: 16px;
  margin: 1.25rem 0 0.5rem 0;
  display: inline-flex;
  align-items: center;
  letter-spacing: 1px;

  justify-content: center;
  width: 100%;
  position: relative;
}
</style>
